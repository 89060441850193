import { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { AddPlanApi } from "../../service/UserServices";

function AddSub() {
  const subscriptionheading = "Add Subscription";
  const benefitheading = "Add Benefits";
  const navigate = useNavigate();
  const globalContext = useContext(GlobalContext);
  const {
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm();
  const [benifits, setBenifits] = useState([{ benifit: "" }]);

  const addSubscriptionPlan = (data) => {
    globalContext.setLoader(true);
    data["benifits"] = benifits.map((d) => d.benifit);
    data["hasTrial"] = data.hasTrial ? "1" : "0";
    AddPlanApi(data)
      .then((res) => {
        globalContext.setLoader(false);
        Swal.fire(
          "Success",
          "Subscription plan added successfully!",
          "success"
        ).then(() => navigate("/subscriptions?page=1"));
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err);
        if (err.response.status === 400)
          Swal.fire(err.response.data.message, "", "error");
      });
  };

  const removeBenifit = (index) => {
    let temp = [...benifits];
    temp.splice(index, 1);
    setBenifits(temp);
  };

  const changeBenifitText = (val, index) => {
    var temp = [...benifits];
    temp[index].benifit = val;
    setBenifits(temp);
  };

  const checkBenifits = () => {
    for (let i = 0; i < benifits.length; i++) {
      if (!benifits[i].benifit.trim()) {
        setError("benifits", { message: "Benefit is required." });
        return;
      } else {
        clearErrors("benifits");
      }
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
        <div className="sidenav py-2">
          <div className="container-fluid">
            <div className="row">
              <div className="addsubscrip">
                <div className="table-main subscription">
                  <div className="main-table-box table-responsive userlist">
                    <div className="heading-top text-left mb-5">
                      <h2>{subscriptionheading}</h2>
                      <h5>
                        <Link to="/dashboard">
                          <i className="fa fa-home"></i>Home
                        </Link>{" "}
                        /{" "}
                        <Link to="/subscriptions?page=1">
                          {" "}
                          Subscription Management
                        </Link>
                        / <Link to="/addSubscription">Add Subscription</Link>
                      </h5>
                    </div>

                    <Form onSubmit={handleSubmit(addSubscriptionPlan)}>
                      <div className="row">
                        <div className="login-data col-lg-6 mt-2">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>SUBSCRIPTION NAME</Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={30}
                              placeholder="Enter Subscription Name"
                              {...register("planName", {
                                onChange: (e) => {
                                  if (e.target.value === " ") {
                                    setValue("planName", "");
                                  }
                                },
                                required: "Subscription name is required.",
                              })}
                            />
                          </Form.Group>
                          {errors.planName && (
                            <span className="error-msg">
                              {errors.planName.message}
                            </span>
                          )}
                        </div>

                        <div className="login-data col-lg-6 mt-2">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasictext"
                          >
                            <Form.Label>SUBSCRIPTION TYPE</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              {...register("durationUnit", {
                                required: "Subscription Type is required.",
                              })}
                            >
                              <option value="">Select Duration</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Yearly">Yearly</option>
                            </Form.Select>
                          </Form.Group>
                          {errors.durationUnit && (
                            <span className="error-msg">
                              {errors.durationUnit.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="row align-items-center">
                        <div className="login-data col-lg-6 mt-2">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>PRICE:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Price"
                              {...register("planPrice", {
                                onChange: (e) => {
                                  if (
                                    e.target.value === " " ||
                                    !/^\d*\.?\d*$/.test(e.target.value)
                                  ) {
                                    setValue("planPrice", "");
                                  }
                                },
                                required: "Price is required.",
                                min: {
                                  value: 1,
                                  message: "Amount should be at least $1",
                                },
                              })}
                            />
                          </Form.Group>
                          {errors.planPrice && (
                            <span className="error-msg">
                              {errors.planPrice.message}
                            </span>
                          )}
                        </div>

                        <div className="login-data col-lg-6 mt-4">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                           <Form.Label htmlFor="trial" className="check-tick">
                              Enable Free Trial
                              <input
                              type="checkbox"
                              id="trial"
                              {...register("hasTrial")}
                              
                            />
                              <span className="checkmark"></span>
                            </Form.Label>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="heading-top text-left mt-5">
                        <h2>{benefitheading}</h2>
                      </div>

                      <div className="row mt-3">
                        {benifits.map((d, i) => (
                          <div className="login-data col-lg-6" key={i}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>BENEFIT</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Benefits"
                                value={d.benifit}
                                maxLength={80}
                                onChange={(e) =>
                                  changeBenifitText(
                                    e.target.value.trimStart(),
                                    i
                                  )
                                }
                              />
                              {benifits.length > 1 && (
                                <span
                                  className="crossbadge"
                                  type="reset"
                                  onClick={() => removeBenifit(i)}
                                >
                                  X
                                </span>
                              )}
                            </Form.Group>
                            {errors.benifits && !d.benifit.trim() && (
                              <span className="error-msg">
                                {errors.benifits.message}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>

                      <div
                        className="add-more"
                        onClick={() => {
                          if (!benifits.some((d) => d.benifit === ""))
                            setBenifits([...benifits, { benifit: "" }]);
                        }}
                      >
                        + Add More
                      </div>
                      <input
                        className="add-subscription"
                        type="submit"
                        value="Add Subscription Plan"
                        onClick={checkBenifits}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddSub;
