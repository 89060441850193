import eye from "../assets/images/eye.png";
import Edit from "../assets/images/edit.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DataPagination } from "../commons/DataPagination";
import {
  SubscriptionListApi,
  UpdatePlanStatusApi,
} from "../../service/UserServices";
import Swal from "sweetalert2";
import { GlobalContext } from "../../context/GlobalContext";
import { ReactTooltip } from "../commons/ReactTooltip";

function SubscriptionList() {
  const headingtitle = "Subscription Management";
  let navigate = useNavigate();
  const [query] = useSearchParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const globalContext = useContext(GlobalContext);

  useEffect(() => {
    let currentPage = query.get("page");
    setPage(parseInt(currentPage));
    getSubscriptions(parseInt(currentPage) - 1);
  }, [query]);

  const getSubscriptions = (currentPage) => {
    globalContext.setLoader(true);
    SubscriptionListApi(currentPage)
      .then((res) => {
        setData(res.data.data);
        setTotalCount(res.data.totalCount);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  function subscriptionPage(id) {
    navigate(`/subscriptiondetail?id=${id}`);
  }

  function editSubscriptionPage(id) {
    navigate(`/editSubscription?id=${id}`);
  }

  function handleClick() {
    navigate("/addSubscription");
  }

  const changePage = (newPage) => {
    navigate({ search: `?page=${newPage}` });
  };

  const changePlanStatus = (index, sub) => {
    Swal.fire({
      title: `${sub.planStatus === "ACTIVE" ? "Deactivate" : "Activate"}`,
      text: `Are you sure, you want to ${
        sub.planStatus === "ACTIVE" ? "deactivate" : "activate"
      } this plan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        let params = {
          planStatus: sub.planStatus === "ACTIVE" ? "DISABLED" : "ACTIVE",
        };
        UpdatePlanStatusApi(sub._id, params)
          .then((res) => {
            let temp = [...data];
            temp[index].planStatus =
              sub.planStatus === "ACTIVE" ? "DISABLED" : "ACTIVE";
            setData(temp);
          })
          .catch((err) => {
            console.log("error: ", err.response);
          });
      }
    });
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
        <div className="table-main subscription">
          <div className="main-table-box table-responsive userlist">
            <div className="d-flex align-items-center subscribe-main">
              <div className="heading-top text-left">
                <h2>{headingtitle}</h2>

                <h5>
                  <Link to="/dashboard">
                    <i className="fa fa-home"></i>Home
                  </Link>{" "}
                  / <> Subscription Plans</>
                </h5>
              </div>
              <div className="btn btn-filter float-end">
                <li className="nav-item">
                  <button
                    className="btn btn-link nav-link"
                    id="bd-theme"
                    type="button"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    onClick={handleClick}
                  >
                    <i className="fa fa-plus"></i>Add Susbcription
                  </button>
                </li>
              </div>
            </div>
            <table className="table py-5 mt-3 bg-black">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Subscription Name</th>
                  <th scope="col">Purchase Count</th>
                  <th scope="col">Price</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((d, i) => (
                    <tr key={i}>
                      <th scope="row">{(page - 1) * 10 + (i + 1)}</th>
                      <td>{d.planName}</td>
                      <td>{d.subscriptionCount}</td>
                      <td>
                        ${d.planPrice}/ {d.durationUnit}
                      </td>
                      <td>
                        <div className="user-toggle" id="my-element">
                          <ReactTooltip
                            text={`${
                              d.planStatus === "ACTIVE" ? "Suspend" : "Activate"
                            }`}
                          >
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={
                                  d.planStatus === "ACTIVE" ? true : false
                                }
                                onChange={(e) => changePlanStatus(i, d)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </ReactTooltip>
                        </div>
                      </td>
                      <td>
                        <ReactTooltip text="View Details">
                          <i
                            className="fa fa-eye eye-bg"
                            onClick={() => subscriptionPage(d._id)}
                          ></i>
                        </ReactTooltip>
                        <ReactTooltip text="Edit Details">
                          <i
                            className="fa fa-edit eye-bg"
                            onClick={() => editSubscriptionPage(d._id)}
                          ></i>
                        </ReactTooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {data.length <= 0 && (
              <div className="text-white no-data">No Data Found</div>
            )}
            {totalCount > 10 && (
              <DataPagination
                page={page}
                totalItems={totalCount}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionList;
