import { useContext, useEffect, useState } from "react";
import eye from "../assets/images/eye.png";
import { DashboardApi } from "../../service/UserServices";
import { GlobalContext } from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { ReactTooltip } from "../commons/ReactTooltip";
import { DateFormat } from "../commons/DateFormat";
import { CancelReasonModal } from "../commons/CancelReasonModal";

function UserDashboard() {
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    getDashboardData();
  }, []);

  const getDashboardData = () => {
    globalContext.setLoader(true);
    DashboardApi()
      .then((res) => {
        setData(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  function closeViewReasonModal() {
    setReason("");
    setShowReason(false);
  }

  return (
    <div className="row dashboard-data">
      <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
        <div className="sidenav py-2">
          <div className="container-fluid dashb">
            <div className="row">
              <div className="row mb-5">
                <div className="col-lg-4 col-sm-4">
                  <div className="card-stats card subscriber-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          <div className="icon-big  icon-warning">
                            <i className="fa fa-bell"></i>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="numbers">
                            <p className="card-category">Subscribed Users</p>
                            <h4 className="card-title">
                              {data?.subscriptionCount}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-sm-4"
                  role="button"
                  onClick={() => navigate("/users?page=1")}
                >
                  <div className="card-stats card user-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          {/* <FontAwesomeIcon icon="fa-brands fa-react" /> */}
                          <i className="fa fa-user"></i>
                        </div>
                        <div className="col-7">
                          <div className="numbers">
                            <p className="card-category">Total Users</p>
                            <h4 className="card-title">{data?.userCount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4">
                  <div className="card-stats card user-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-3">
                          <i className="fa fa-money"></i>
                        </div>
                        <div className="col-7">
                          <div className="numbers">
                            <p className="card-category">Revenue</p>
                            <h4 className="card-title">
                              ${data?.totalRevenue}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="user-table">
                <div className="main-table-box table-responsive userlist">
                  <div className="row mt-0 mb-5">
                    <div className="col-md-12">
                      <div className="table-heading">
                        <div className="heading-top text-left">
                          <h2>Recent Subscribers</h2>
                        </div>
                      </div>
                      <table className="table py-5 mt-3 bg-black">
                        <thead>
                          <tr>
                            <th scope="col">User Name</th>
                            <th scope="col">Subscription Plan</th>
                            <th scope="col">Expiry Date</th>
                            <th scope="col">Plan Price</th>
                            <th scope="col">Price Paid</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.latestSubscription?.length > 0 &&
                            data?.latestSubscription.map((d, i) => (
                              <tr key={i}>
                                <th scope="row">
                                  {d._user.firstName + " " + d._user.lastName}
                                </th>
                                <td>{d._subscription.planName}</td>
                                <td>
                                  {d.status === "CANCELLED" ? (
                                    <span>
                                      {`Cancelled by ${
                                        d.cancelledBy === "U" ? "User" : "Admin"
                                      }`}{" "}
                                      <span
                                        className="view-reason"
                                        onClick={() => {
                                          setReason(d.cancelReason);
                                          setShowReason(true);
                                        }}
                                      >
                                        View Reason
                                      </span>
                                    </span>
                                  ) : new Date(d.expireAt) < new Date() ? (
                                    "Expired"
                                  ) : (
                                    DateFormat(d.expireAt)
                                  )}
                                </td>
                                <td>
                                  ${d.planPrice}/{d._subscription.durationUnit}
                                </td>
                                <td>${d?.amountPaid ? d?.amountPaid : "0"}</td>
                                <td>
                                  <ReactTooltip text="View Details">
                                    <img
                                      src={eye}
                                      alt="eye"
                                      onClick={() =>
                                        navigate(
                                          `/userdetail?id=${d._user._id}`
                                        )
                                      }
                                    />
                                  </ReactTooltip>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {data?.latestSubscription?.length <= 0 && (
                        <div className="text-white">No Data Found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CancelReasonModal
        reason={reason}
        showReason={showReason}
        hide={closeViewReasonModal}
      />
    </div>
  );
}
export default UserDashboard;
