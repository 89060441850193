import { useContext, useEffect, useState } from "react";
import tick from "../assets/images/tick.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  CancelUserSubscription,
  UserDetailApi,
  UserTransactions,
} from "../../service/UserServices";
import { GlobalContext } from "../../context/GlobalContext";
import { DateFormat } from "../commons/DateFormat";
import { DataPagination } from "../commons/DataPagination";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { CancelReasonModal } from "../commons/CancelReasonModal";
import { BASE_URL } from "../../configuration/Config";
import { ImageLoader } from "../commons/ImageLoader";

function UserDetail() {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const usertitle = "User Detail";
  const [query] = useSearchParams();
  const [data, setData] = useState({});
  const globalContext = useContext(GlobalContext);
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [reasonModal, setReasonModal] = useState(false);
  const [update, setUpdate] = useState(0);
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    let user = query.get("id");
    getUserDetail(user);
    getUserTransacions(user);
  }, [query, page, update]);

  const getUserDetail = (user) => {
    globalContext.setLoader(true);
    UserDetailApi(user)
      .then((res) => {
        setData(res.data.data);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  const getUserTransacions = (id) => {
    UserTransactions(id, page - 1)
      .then((res) => {
        setTransactions(res.data.data);
        setTotalCount(res.data.totalCount);
      })
      .catch((err) => {
        console.log("error: ", err.response);
      });
  };

  const closeReasonModal = () => {
    setReasonModal(false);
    reset({ reason: "" });
  };

  function closeViewReasonModal() {
    setReason("");
    setShowReason(false);
  }

  const cancelUserSubscription = (data) => {
    globalContext.setLoader(true);
    CancelUserSubscription(query.get("id"), data)
      .then((res) => {
        globalContext.setLoader(false);
        closeReasonModal();
        setUpdate((temp) => temp + 1);
        Swal.fire(
          "Success",
          "Subscription plan cancelled successfully!",
          "success"
        );
      })
      .catch((err) => {
        globalContext.setLoader(false);
        closeReasonModal();
        setUpdate((temp) => temp + 1);
        console.log("Error: ", err);
        if (err.response.status === 400)
          Swal.fire(err.response.data.message, "", "error");
      });
  };

  return (
    <>
      <div className="row">
        <div
          className={`col-lg-12 col-12 px-4 py-4 bg-black user-table ${
            transactions.length && "user-details"
          }`}
        >
          <div className="sidenav py-2">
            <div className="container-fluid">
              <div className="row">
                <div className="table-main subscription">
                  <div className="main-table-box table-responsive userlist">
                    <div className="d-flex align-items-center subscribe-main mb-4">
                      <div className="heading-top text-left">
                        <h2>{usertitle}</h2>
                        <h5>
                          <Link to="/dashboard">
                            <i className="fa fa-home"></i>Home
                          </Link>{" "}
                          / <Link onClick={() => navigate(-1)}>Users List</Link>
                          / <>User Detail</>
                        </h5>
                      </div>
                      {data?._subscription && (
                        <div className="cancel-sub">
                          <Button
                            variant="unset"
                            onClick={() => setReasonModal(true)}
                          >
                            Cancel Subscription
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {data?.profilePic && (
                        <div className="col-lg-2">
                          <div className="profile-pic">
                            <ImageLoader
                              image={BASE_URL + data.profilePic}
                              width={200}
                              height={200}
                            />
                          </div>
                        </div>
                      )}
                      {data?._subscription && (
                        <>
                          <div className="col-lg-3 offset-md-1">
                            <div className="enterprice">
                              <h5 className="text-white">
                                {data?._subscription?.planName}
                              </h5>
                              <h2>
                                ${data?.planPrice}/
                                <span>{data?._subscription?.durationUnit}</span>
                              </h2>
                              <ul>
                                {data?._subscription?.benifits.map((b) => (
                                  <li>
                                    <img src={tick} alt="tick" />
                                    {b}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            {data?.isTrial === "1" && (
                              <div className="trial-period">
                                Free trial available for 48 hours
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      <div className="col-lg-5 offset-md-1">
                        <div className="subscrip-name">
                          <table className="table-responsive w-100 border-0">
                            <tr>
                              <th>Name:</th>
                              <td>
                                {data?.firstName &&
                                  data?.lastName &&
                                  data.firstName + " " + data?.lastName}
                              </td>
                            </tr>
                            <tr>
                              <th>Email:</th>
                              <td>{data.email}</td>
                            </tr>
                            <tr>
                              <th>Subscription Plan:</th>
                              <td>
                                {data.activePlan ? data.activePlan : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>Expiry Date:</th>
                              <td>
                                {data.planExpiry
                                  ? new Date(data.planExpiry) < new Date()
                                    ? "Expired"
                                    : DateFormat(data.planExpiry)
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>Price:</th>
                              <td>
                                {data.planPrice ? `$${data.planPrice}` : "N/A"}
                              </td>
                            </tr>
                          </table>
                          {data?._subscription?.planStatus === "DISABLED" && (
                            <h6 className="note-disabled mt-4 text-white">
                              <span className="text-danger">Note:</span> Plan is
                              disabled, Current purchase will be functional till{" "}
                              {DateFormat(data?.planExpiry)} and then all
                              featured will get disabled.
                            </h6>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {transactions.length > 0 && (
        <div className="row">
          <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
            <div className="sidenav py-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="table-main subscription">
                    <div className="main-table-box table-responsive userlist">
                      <div className="d-flex align-items-center subscribe-main mb-4">
                        <div className="heading-top text-left">
                          <h2>User Transactions</h2>
                        </div>
                      </div>
                      <div className="row">
                        <table className="table py-5 mt-3 bg-black">
                          <thead>
                            <tr>
                              <th scope="col">Paypal Subscription ID</th>
                              <th scope="col">Subscription Plan</th>
                              <th scope="col">Plan Price</th>
                              <th scope="col">Price Paid</th>
                              <th scope="col">Purchased On</th>
                              <th scope="col">Expiry Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((t, j) => (
                              <tr key={j}>
                                <td>{t.paypalSubscriptionId}</td>
                                <td>{t._subscription?.planName}</td>
                                <td>
                                  ${t.planPrice}/{t._subscription?.durationUnit}
                                </td>
                                <td>${t?.amountPaid ? t?.amountPaid : "0"}</td>
                                <td>{DateFormat(t.startTime)}</td>
                                <td>
                                  {t.status === "CANCELLED" ? (
                                    <span>
                                      {`Cancelled by ${
                                        t.cancelledBy === "U" ? "User" : "Admin"
                                      }`}{" "}
                                      <span
                                        className="view-reason"
                                        onClick={() => {
                                          setReason(t.cancelReason);
                                          setShowReason(true);
                                        }}
                                      >
                                        View Reason
                                      </span>
                                    </span>
                                  ) : new Date(t.expireAt) < new Date() ? (
                                    "Expired"
                                  ) : (
                                    DateFormat(t.expireAt)
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {totalCount > 10 && (
                          <DataPagination
                            page={page}
                            totalItems={totalCount}
                            changePage={(e) => setPage(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={reasonModal}
            onHide={() => closeReasonModal()}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>Cancelation Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter reason here..."
                maxLength={128}
                {...register("reason", {
                  onChange: (e) => {
                    if (e.target.value.trim() === "") {
                      setValue("reason", "");
                    }
                  },
                  required: "Reason is required",
                })}
              />
              {errors.reason && (
                <span className="error-msg">{errors.reason.message}</span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => closeReasonModal()}>
                Close
              </Button>
              <Button
                variant="danger"
                onClick={handleSubmit(cancelUserSubscription)}
              >
                Cancel Subscription
              </Button>
            </Modal.Footer>
          </Modal>

          <CancelReasonModal
            reason={reason}
            showReason={showReason}
            hide={closeViewReasonModal}
          />
        </div>
      )}
    </>
  );
}
export default UserDetail;
