import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { DataPagination } from "../commons/DataPagination";
import { TotalTransactions } from "../../service/UserServices";
import { GlobalContext } from "../../context/GlobalContext";
import { DateFormat, FormatDate } from "../commons/DateFormat";
import { Button } from "react-bootstrap";
import { CancelReasonModal } from "../commons/CancelReasonModal";

function TransactionList() {
  const headingtitle = "Transaction Management";
  let navigate = useNavigate();
  const [query] = useSearchParams();
  const globalContext = useContext(GlobalContext);
  const todayDate = FormatDate(new Date());
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState({ start: "", end: "" });
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    globalContext.setLoader(true);
  }, []);

  useEffect(() => {
    let currentPage = query.get("page");
    let searchData = query.get("search");
    let startDate = query.get("start");
    let endDate = query.get("end");
    setPage(parseInt(currentPage));
    setSearch(searchData);
    setDateFilter({
      start: startDate,
      end: endDate,
    });
    getAllTransactions(
      parseInt(currentPage) - 1,
      searchData,
      startDate,
      endDate
    );
  }, [query]);

  const getAllTransactions = (currentPage, searchData, startDate, endDate) => {
    TotalTransactions(currentPage, searchData, startDate, endDate)
      .then((res) => {
        setData(res.data.data);
        setTotalCount(res.data.totalCount);
        globalContext.setLoader(false);
      })
      .catch((err) => {
        globalContext.setLoader(false);
        console.log("error: ", err.response);
      });
  };

  const changePage = (newPage) => {
    globalContext.setLoader(true);
    navigate({
      search: `?page=${newPage}&search=${search}&start=${dateFilter.start}&end=${dateFilter.end}`,
    });
  };

  function closeViewReasonModal() {
    setReason("");
    setShowReason(false);
  }

  const changeStartDate = (date) => {
    if (
      (dateFilter.end && date <= dateFilter.end && date <= todayDate) ||
      (!dateFilter.end && date <= todayDate)
    ) {
      setDateFilter({ ...dateFilter, start: date });
      navigate({
        search: `?page=${1}&search=${search}&start=${date}&end=${
          dateFilter.end
        }`,
      });
    }
  };

  const changeEndDate = (date) => {
    if (date <= todayDate && date >= dateFilter.start) {
      setDateFilter({ ...dateFilter, end: date });
      navigate({
        search: `?page=${1}&search=${search}&start=${
          dateFilter.start
        }&end=${date}`,
      });
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12 col-12 px-4 py-4 bg-black user-table">
        <div className="table-main subscription">
          <div className="main-table-box table-responsive userlist">
            <div className="d-flex align-items-center subscribe-main">
              <div className="heading-top text-left">
                <h2>{headingtitle}</h2>

                <h5>
                  <Link to="/dashboard">
                    <i className="fa fa-home"></i>Home
                  </Link>{" "}
                  / <> Transaction History</>
                </h5>
              </div>
              <div className="search-right-main">
                <div className="search-filter-all">
                  <input
                    className="search-user"
                    placeholder="Search Here.."
                    value={search}
                    onChange={(e) => {
                      navigate({
                        search: `?page=${1}&search=${e.target.value.trimStart()}&start=${
                          dateFilter.start
                        }&end=${dateFilter.end}`,
                      });
                    }}
                    type="text"
                  />
                  <i
                    className="search-user-data fa fa-search"
                    aria-hidden="true"
                  ></i>
                </div>

                <div className="btn btn-filter float-end">
                  <div>
                    <input
                      type="date"
                      max={dateFilter.end ? dateFilter.end : todayDate}
                      value={dateFilter.start}
                      onChange={(e) => changeStartDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      min={dateFilter.start}
                      max={todayDate}
                      disabled={dateFilter.start ? false : true}
                      value={dateFilter.end}
                      onChange={(e) => changeEndDate(e.target.value)}
                    />
                  </div>
                  <div>
                    {(dateFilter.start || dateFilter.end) && (
                      <Button
                        variant="unset"
                        onClick={() =>
                          navigate({
                            search: `?page=1&search=${search}&start=&end=`,
                          })
                        }
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <table className="table py-5 mt-3 bg-black">
              <thead>
                <tr>
                  <th scope="col">Paypal Subscription ID</th>
                  <th scope="col">User Name</th>
                  <th scope="col">Subscription Plan</th>
                  <th scope="col">Plan Price</th>
                  <th scope="col">Price Paid</th>
                  <th scope="col">Purchased On</th>
                  <th scope="col">Expiry Date</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 &&
                  data.map((t, i) => (
                    <tr key={i}>
                      <td>{t.paypalSubscriptionId}</td>
                      <td>{t._user.firstName + " " + t._user.lastName}</td>
                      <td>{t._subscription.planName}</td>
                      <td>
                        ${t.planPrice}/{t._subscription.durationUnit}
                      </td>
                      <td>${t?.amountPaid ? t?.amountPaid : "0"}</td>
                      <td>{DateFormat(t.startTime)}</td>
                      <td>
                        {t.status === "CANCELLED" ? (
                          <span>
                            {`Cancelled by ${
                              t.cancelledBy === "U" ? "User" : "Admin"
                            }`}{" "}
                            <span
                              className="view-reason"
                              onClick={() => {
                                setReason(t.cancelReason);
                                setShowReason(true);
                              }}
                            >
                              View Reason
                            </span>
                          </span>
                        ) : new Date(t.expireAt) < new Date() ? (
                          "Expired"
                        ) : (
                          DateFormat(t.expireAt)
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {data.length <= 0 && (
              <div className="text-white no-data">No Data Found</div>
            )}
            {totalCount > 10 && (
              <DataPagination
                page={page}
                totalItems={totalCount}
                changePage={changePage}
              />
            )}
          </div>
        </div>
      </div>
      <CancelReasonModal
        reason={reason}
        showReason={showReason}
        hide={closeViewReasonModal}
      />
    </div>
  );
}
export default TransactionList;
